.MainAreaCart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;

}

.inMainAreaCart {
    max-width: 1296px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 59px;

}

.titleCart {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 38px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    gap: 24px;
    margin-bottom: 32px;
}

.titleCart span {
    margin-left: 24px;
    margin-right: 24px;
}

.areaListItemsCart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
}

.inAreaItemsCart {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    padding: 0 24px;
    width: 100%;
}

.leftPartAreaCart {
    max-width: 976px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-direction: column;
}

.itemDonate {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    align-self: stretch;
    border-radius: 16px;
    background: rgba(29, 36, 35, 0.78);
}

.inItemDonate {
    padding: 16px 24px 20px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
}

.mainAreaItemDonate {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}

.imageDonateItem {
    display: flex;
    max-width: 98px;
    width: 98px;
    height: 98px;

}

.imageDonateItem img {
    object-fit: cover;
    width: auto;
    height: auto;
}

.infoDonateItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}



.infoDonateItem input {
    display: flex;
    width: 116px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 12px;
    border: 1px solid #2D3431;
    background: #131B1A;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    color: #FFF;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    height: 44px;
    padding: 0px 12px;
}

.nameDonateItem {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.descDonateItem {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-height: 54px;
    overflow: hidden;
}

.dataDonateResult {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
}

.mainPriceDonate {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.btnRemoveItem {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #FFF;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 8px;
    border: 2px solid rgba(6, 12, 10, 0.46);
    background: rgba(0, 0, 0, 0.30);
    padding: 4px 6px;
    cursor: pointer;
}

.btnRemoveItem span {
    display: flex;
}

.rightPartCart {
    max-width: 417px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 130px;
}

.inRightPartCart {

    display: flex;
    flex-direction: column;
}

.areaResultCart {
    display: flex;
    align-items: center;
    justify-self: center;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-clip: border-box !important;
    background-position: center !important;

}

.inResultCart {
    padding: 16px 12px;
}

.titleResultCart {
    display: flex;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
}

.textResultCart {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 4px;
}

.priceMainCart {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 21px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.areaInputsCart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    flex-direction: column;
    width: 100%;
}

.areaInputsCart input {
    display: flex;
    padding: 0px 12px;
    border-radius: 4px;
    border: none;
    border-radius: 12px;
    height: 44px;
    border: 1px solid #2D3431;
    background: #131B1A;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    color: #B9B9B9;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: calc(100% - 24px);
}

.paybtn {
    color: #F3F3F3;
    font-family: Nunito;
    font-size: 19px;
    width: 100%;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    display: flex;
    height: 44px;
    padding: 0px 24px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: linear-gradient(91deg, #FE6844 15.81%, #FFCA46 83.76%);
    border-style: hidden;
}

.simpleCartPolicy {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 8px;
}

.colored {
    color: #FE8844;
}

.blockPromocode {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    background-clip: border-box !important;
    background-position: center !important;

}

.inPromoCode {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12px 12px;
    gap: 8px;
}

.titlePromo {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.inPromoCode input {
    display: flex;
    padding: 0px 12px;
    border-radius: 4px;
    border: none;
    border-radius: 12px;
    height: 44px;
    border: 1px solid #2D3431;
    background: #131B1A;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    color: #B9B9B9;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    width: calc(100% - 24px);
}

.acceptPromo {
    color: #F3F3F3;
    font-family: Nunito;
    font-size: 19px;
    width: 100%;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    display: flex;
    height: 44px;
    padding: 0px 24px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: linear-gradient(91deg, #FE6844 15.81%, #FFCA46 83.76%);
    border-style: hidden;
}

.acceptPromo:hover,
.paybtn:hover {
    transition: .5s;
    background: linear-gradient(91deg, #ff4a1d 15.81%, #ffbd17 83.76%);
}

.nonCart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 38px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    height: 75vh;
}

.notification {
    display: none;
    position: fixed;
    top: 54px;
    right: 54px;
    padding: 24px;
    border-radius: 12px;
    background: linear-gradient(91deg, #FE6844 15.81%, #FFCA46 83.76%);
    color: #FFF;
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    z-index: 999999;
}

.smallPrice {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}

.promoCodeText {
    color: #ffd667;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 136.291%;
    justify-content: center;
}

@media screen and (max-width: 1020px) {
    .inAreaItemsCart {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 24px;
        padding: 0 24px;
        width: 100%;
        flex-direction: column;
    }

    .rightPartCart {
        max-width: unset;
        width: 100%;
        display: flex;
        flex-direction: column;
        position: sticky;
        top: 130px;
    }

    .inResultCart {
        padding: 16px 12px;
        width: -webkit-fill-available;
    }
}

@media screen and (max-width: 1050px) {

}

@media screen and (max-width: 530px) {
    .leftPartAreaCart {
        gap: 12px;
    }

    .textResultCart {
        font-size: 20px;
    }

    .priceMainCart {
        font-size: 19px;
    }

    .smallPrice {
        font-size: 16px;
    }

    .mainAreaItemDonate {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        width: -webkit-fill-available;
    }

    .imageDonateItem {
        display: flex;
        max-width: unset;
        width: 100%;
        height: auto;
        align-items: center;
        justify-content: center;
    }

    .imageDonateItem img {
        object-fit: cover;
        width: auto;
        height: 50vw;
    }

    .inItemDonate {
        padding: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        width: 100%;
        flex-direction: column;
    }

    .dataDonateResult {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    .infoDonateItem input {
        width: -webkit-fill-available;
    }

    .nameDonateItem {
        font-size: 24px;
    }

    .titleCart {
        font-size: 24px;
        gap: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .titleCart svg {
        transform: scale(0.5);
    }

    .nonCart {
        font-size: 24px;
        height: 35vw;
    }

}