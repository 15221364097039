.Main {
    display: flex;
    flex-direction: column;
    height: clamp(15vw, 80vw, 100vw);
}


.parallaxContainer {
    position: relative;
    height: clamp(15vw, 80vw, 100vw);
    /* Сделаем страницу достаточно длинной для демонстрации прокрутки */
    overflow: hidden;
}

.parallaxLayer {
    position: absolute;
    width: 100%;
    height: clamp(15vw, 80vw, 100vw);
    /* Каждый слой занимает весь экран по высоте */
    top: 0;
    left: 0;
}

.parallaxImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Для корректного отображения изображений */
}


.parallax {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;
    overflow: hidden;

}

.layer1 {
    position: absolute;
    top: 0;
    z-index: 20;
    transition: 0.8s;
}

.layer2 {
    position: absolute;
    top: 0;
    z-index: 25;
    transition: 1s;
}

.layer3 {
    position: absolute;
    top: 0;
    z-index: 30;
    transition: 0.8s;
}

.layer4 {
    position: absolute;
    top: 0;
    z-index: 35;
    transition: 0.6s;
}

.layer5 {
    position: absolute;
    top: 0;
    z-index: 40;
    transition: 0.4s;
}

.playstrix {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
    color: #FDD7BE;
    font-family: "Baloo Bhai 2";
    font-size: 255px;
    font-style: normal;
    font-weight: 800;
    filter: drop-shadow(15px 15px 0px #FF6745);
    line-height: normal;
    z-index: 55;
}

.aboutServerInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 138px;
    margin-bottom: 200px;
}

.blockTitle {
    max-width: 1296px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
}

.titleInfo {
    margin-left: 64px;
    margin-right: 64px;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 49px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.textArea {
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.inBlockServerInfo {
    max-width: 1296px;
    width: 100%;
}

.serverstypes {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.topLeaves {
    position: absolute;
    top: -24px;
    width: 100%;
}

.bottomLeaves {
    position: absolute;
    bottom: -24px;
    width: 100%;
}

.topLeaves img {
    width: 100%;
    object-fit: cover;
}

.bottomLeaves img {
    width: 100%;
    object-fit: cover;
}

.inServersType {

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: clamp(64px, 25vw, 400px);
    margin-bottom: clamp(64px, 25vw, 250px);
    z-index: 5;
    gap: 16px;
}

.serverBlockInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1047px;
    width: auto;

    cursor: pointer;
}

.preTextServer {
    align-items: center;
    border-radius: 24px;
    background: #101615;
    display: none;
    width: 98px;
    height: 608px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preTextServer span {
    transform: rotate(-90deg);
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.44);
    font-family: Nunito;
    font-size: 49px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.blockMainDataServer {
    height: auto;
    display: none;
    border-radius: 24px;
    background: #101615;
    overflow: hidden;
    width: 100%;
    max-width: unset;
    height: 608px;
    overflow: hidden;
}

.blockAboutServer {
    padding: 24px 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 75%;
}

.titleServer {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.44);
    font-family: Nunito;
    font-size: 49px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.descServer {
    color: #FFF;
    font-family: Nunito;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 100%;
    overflow: auto;
    text-indent: 14px;
}
.descServer::-webkit-scrollbar{
    background: #818181;
    width: 8px;
    border-radius: 16px;
}
.descServer::-webkit-scrollbar-thumb{
    background: #3b3b3b;
    border-radius: 16px;
}

.showServerAnim {
    display: flex;
    animation: showserver 0.5s forwards;
}

@keyframes showserver {
    0% {
        display: flex;
        transform: translateX(-30%);
        opacity: 0;
    }

    20% {
        display: flex;

        opacity: 0.5;
    }

    100% {
        display: flex;
        transform: translateX(0%);
    }
}

.showLineServer {
    display: flex;
}

.imagesWrap {
    height: 360px;
    margin-right: .84rem;
    -webkit-mask-image: url(./images/livesmask.svg);
    mask-image: url(./images/livesmask.svg);
    -webkit-mask-size: cover;
    mask-size: cover;
    overflow: hidden;
    position: relative;
    transition: width .5s;
    max-width: 610px;
    width: 100%;
}

.imagesWrap img {
    position: absolute;
    top: 0;
}

.scrollBlock {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.textWrap {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    vertical-align: initial;
    max-width: 636px;
    width: 100%;
    gap: 69px;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 250px; */

}

.textWrap::-webkit-scrollbar {
    display: none;
}

.pinSpacer {
    max-width: 610px;
    width: 100%;
    overflow: hidden;
    height: 367px;
}

.inScrollBlock {
    display: flex;
    flex-direction: column;
    gap: 68px;
    max-width: 1296px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.textBlock {
    display: flex;
    align-items: start;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    margin-bottom: 150px;
    gap: 12px
}

.descText {
    max-width: 351px;
    width: 100%;
    color: #FFF;
    font-family: Nunito;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.textH2 {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 33px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.scrollArea {
    display: flex;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;
    height: 500px;
    gap: 12px;
}

.titleInfoScroll {
    margin-left: 64px;
    margin-right: 64px;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 49px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    width: 100%;
    display: flex;
}

.ourCommand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.inOurCommand {
    max-width: 1294px;
    width: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
}

.titleOurCommand {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 49px;
    font-style: normal;
    font-weight: 900;
    line-height: 99.02%;
    /* margin-bottom: 64px; */
}

.cardsCommand {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    max-width: 1294px;
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding-bottom: 24px;
}

.cardsCommand::-webkit-scrollbar {
    display: none;
}

.incardCommands {
    display: flex;
    flex-direction: row;
    gap: 18px;
    width: max-content;
    margin-top: 64px;
}

.itemCardCommand {
    width: 310px;
    height: 389px;
    perspective: 1000px;
    /* Важен для 3D эффекта */
    position: relative;
}

.frontface,
.backface {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    /* Скрываем заднюю сторону */
    transition: transform 0.6s ease-in-out;
    /* Плавный переход */
}

.frontface {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transform: rotateY(0deg);
    /* Начальное положение */
}

.backface {
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    flex-direction: column;
    transform: rotateY(180deg);
    /* Положение перевернутой стороны */
}

.backImageBack {
    position: absolute;
    top: 0;
}

.backImageBack img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.titleBackCard {
    color: #FFF;
    text-align: center;
    font-family: "Nunito";
    font-size: clamp(12px, 2vw, 22px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    z-index: 11;
    margin-top: clamp(14px, 2vw, 20px);
}



.descBackCard {
    color: #EDBDFC;
    text-align: center;
    font-family: Nunito;
    font-size: clamp(10px, 2vw, 19px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    z-index: 11;
    max-width: 274px;
}

.itemCardCommand:hover .backface {
    transform: rotateY(0deg);
    /* Поворот задней стороны */
}

.itemCardCommand:hover .frontface {
    transform: rotateY(180deg);
    /* Поворот передней стороны */
}


.nicknameBackCard {
    z-index: 11;
    text-align: center;
    font-family: "Nunito";
    font-size: clamp(15px, 2vw, 29px);
    font-weight: 900;
    background: linear-gradient(180deg, #FFAA45 23.06%, #FF6745 78.88%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: clamp(8px, 2vw, 12px);
}

.nameCard {
    z-index: 11;
    text-align: center;
    font-family: "Nunito";
    font-size: clamp(15px, 2vw, 29px);
    font-weight: 900;
    background: linear-gradient(180deg, #FFAA45 23.06%, #FF6745 78.88%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: clamp(12px, 2vw, 17px);
}

.imagecard {
    z-index: 11;

    display: flex;
    align-items: center;
    justify-content: center;
}

.imagecard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.backImageFront {
    position: absolute;
    top: 0;
    z-index: 10;
}

.backImageFront img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.preBtn {
    display: flex;
    height: clamp(32px,5vw,56px);
    align-items: center;
    justify-content: center;
    width: clamp(32px,5vw,56px);
    border-radius: 50%;
    border: 2px solid #FFAB45;
}

.nextBtn {
    display: flex;
    height: clamp(32px,5vw,56px);
    align-items: center;
    justify-content: center;
    width: clamp(32px,5vw,56px);
    border-radius: 50%;
    border: 2px solid #FFAB45;
}

.btnsCommand {
    width: 100%;
    display: flex;
    justify-content: right;
    gap: 16px;
}

.nextBtn:hover,
.preBtn:hover {
    cursor: pointer;
    background: linear-gradient(180deg, #FFAD45 0%, #FF6745 100%);
}

.connectToPlaystrix {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: auto;

}

.inConnectToPLaystrix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 1278px;
}

.topImageConnect {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 31;
}

.topImageConnect img {
    width: 100%;
    height: 3%;
    object-fit: cover;
}

.mainAreaConnect {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 159px;
    width: 100%;
    height: 100%;
}

.mainFooterImageBack {
    width: 100%;
    height: 100%;
}

.mainFooterImageBack img {
    width: 100%;
    height: 80%;
    object-fit: cover;
}
.imageServer{
    max-width: 65%;
    overflow: hidden;
}
.imageServer img{
    width: 130%;
    height: 100%;
    object-fit: cover;
}
.textConnect {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    -webkit-text-stroke-width: 2;
    -webkit-text-stroke-color: #925A86;
    font-family: Nunito;
    font-size: 78px;
    font-style: normal;
    font-weight: 900;
    line-height: 99.02%;
    max-width: 1044px;
    position: absolute;
    z-index: 31;
    top: 406px;
}

.socialIcon {

    top: 0;
    z-index: 35;
    top: 600px;
 
}
.areaSocIcons{
    position: absolute;
    top: 600;
    z-index: 35;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
}

.socialIcon svg path:hover{
    transition: 0.7s;
    fill: rgb(248, 236, 211);
}

.bottomImageConnect {
    z-index: 31;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.inBottomImage {
    position: relative;
    display: flex;
    height: 650px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.rocksImage {
    bottom: 0;
    width: 100%;
    position: absolute;
}

.grassImage {
    width: 100%;
    bottom: 50px;
    position: absolute;
}

.grassImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rocksImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1100px) {
    .inServersType {
        flex-direction: column;
        padding: 0 12px;
    }

    .preTextServer span {
        transform: rotate(0);
    }

    .preTextServer {
        width: 100%;
        height: 98px;
    }

    .serverBlockInfo {
        width: 100%;
    }

    .descServer {
        font-size: clamp(16px, 3vw, 23px);
    }

    .serverBlockInfo {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: -webkit-fill-available;
        width: 100%;
        cursor: pointer;
        padding: 0 12px;
    }



}

@media screen and (max-width: 1050px) {
    .parallaxImage {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .Main {
        display: flex;
        flex-direction: column;

    }
    .blockMainDataServer{
        height: auto;
    }

    .parallaxContainer {
        position: relative;

        /* Сделаем страницу достаточно длинной для демонстрации прокрутки */
        overflow: hidden;
    }

    .parallaxLayer {
        position: absolute;
        width: 100%;
        /* height: clamp(25vw,35vw,50vw); */
        /* Каждый слой занимает весь экран по высоте */
        top: 0;
        left: 0;
    }

    .parallaxImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* Для корректного отображения изображений */
    }

    .titleInfo {
        margin-left: 24px;
        margin-right: 24px;
        font-size: clamp(32px, 3vw, 49px);
    }

    .textArea {
        font-size: clamp(18px, 3vw, 26px);
        padding: 0 12px;
    }

    .aboutServerInfo {
        margin-top: clamp(64px, 15vw, 138px);
        margin-bottom: clamp(64px, 15vw, 200px);
    }

    .inServersType {
        margin-top: clamp(64px, 25vw, 400px);
        margin-bottom: clamp(64px, 15vw, 250px);
    }

    .preTextServer {
        font-size: clamp(32px, 3vw, 98px) !important;
    }

    .blockMainDataServer {
        border-radius: 16px;

        flex-direction: column;
    }

    .imageServer {
        display: flex;
        width: 100%;
        max-width: unset;
        height: clamp(300px,24vw,450px);
        overflow: hidden;
    }

    .imageServer img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .blockAboutServer {
        padding: 24px;
        width: unset;
    }

    .titleServer {
        font-size: clamp(26px, 3vw, 49px);
    }

    .preTextServer span {
        font-size: clamp(26px, 3vw, 49px);
    }

    .preTextServer {
        height: clamp(48px, 10vw, 98px);
    }

    .scrollArea {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
        overflow: hidden;
        height: auto;
    }

    .textBlock {
        margin-bottom: 24px;
        flex-direction: column;
    }

    .imagesWrap {
        height: 400px;
        margin-right: 0;
        -webkit-mask-image: url(./images/livesmask.svg);
        mask-image: url(./images/livesmask.svg);
        -webkit-mask-size: cover;
        mask-size: cover;
        overflow: hidden;
        position: relative;
        transition: width .5s;
        max-width: unset;
        width: 100%;
    }

    .pinSpacer {
        max-width: unset;
        width: 100%;
        overflow: hidden;
        height: 367px;
    }

    .imagesWrap img {
        width: 100%;
    }

    .imagesWrap {
        gap: 24px;
        padding: 0 12px;
        width: unset;
    }

    .textWrap {
        gap: 24px;
        width: unset;
        max-width: unset;
        padding: 0 12px;
    }

    .descText {
        max-width: unset;
        width: 100%;
        color: #FFF;
        font-family: Nunito;
        font-size: clamp(16px, 3vw, 23px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .textH2 {
        font-size: 30px;
        margin-top: 24px;
    }

    .pinSpacer {
        height: auto;
    }

    .inOurCommand {
        padding: 0 12px;
        max-width: -webkit-fill-available;
    }

    .incardCommands {
        margin-top: 24px;
    }

    .titleOurCommand,
    .titleInfoScroll {
        font-size:
            clamp(36px, 3vw, 49px);
    }
    .titleInfoScroll{
        margin-left: 0;
        margin-right: 0;
        padding: 0 12px;
        max-width: -webkit-fill-available;
    }
    .itemCardCommand {
        width: clamp(156px, 30vw, 310px);
        ;
        height: clamp(196px, 36vw, 389px);
        ;
        perspective: 1000px;
        position: relative;
    }

    .descBackCard {
        color: #EDBDFC;
        text-align: center;
        font-family: Nunito;
        font-size: clamp(8px, 1.5vw, 19px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        word-break: keep-all;
        z-index: 11;
        padding: 0 12px;
        max-width: 274px;
        display: flex;

    }

    .inConnectToPLaystrix {
        height: clamp(600px, 100vw, 1278px);
    }

    .textConnect {
        top: clamp(24px, 15vw, 406px);
        font-size: clamp(16px, 3vw, 78px)
        ;
    }

    .socialIcon {

    }
    .areaSocIcons{
        position: absolute;
        top: 0;
        z-index: 35;
        gap: 50px;
        top: clamp(42px, 20vw, 600px);
    }
    .grassImage {
        bottom: clamp(5px, 1vw, 50px);
    }

    .socialIcon svg {
        height: clamp(24px, 5vw, 64px);
        width: clamp(24px, 5vw, 64px);
    }

    .rocksImage {
        bottom: clamp(-3px, 3vw, 0px);
    }

    .inBottomImage {
        height: clamp(300px, 15vw, 650px);
        margin-bottom: -24px;
    }



    .topImageConnect {
        top: clamp(30px, 35vw, 0px);
    }

    .Main {
        margin-top: 104px;
    }
}

@media screen and (max-width: 500px) {
    .imagesWrap {
        height: 250px;
    }
    .inConnectToPLaystrix {
        height: clamp(450px, 50vw, 1278px);
    }

    .topImageConnect {
        top: clamp(100px, 35vw, 0px);
    }

    .socialIcon {
        position: absolute;
        top: 0;
        z-index: 35;
        top: clamp(42px, 29vw, 600px);
    }
    .socialIcon svg {
        height: clamp(32px, 5vw, 32px);
        width: clamp(32px, 5vw, 32px);
    }
    .areaSocIcons {

        top: clamp(42px, 10vw, 400px);
    }
}