.parallaxContainer {
    position: relative;
    height: 55vw;
    /* Сделаем страницу достаточно длинной для демонстрации прокрутки */
    overflow: hidden;
}

.parallaxLayer {
    position: absolute;
    width: 100%;
    height: 100vw;
    /* Каждый слой занимает весь экран по высоте */
    top: 0;
    left: 0;
}

.parallaxImage {
    width: 100%;
    height: auto;
    object-fit: fill;
    /* Для корректного отображения изображений */
}


.parallax {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;
    overflow: hidden;

}

.areaShop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 88px;
}

.inShop {
    max-width: 1296px;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.inShopBuy {
    max-width: 1296px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.areaCardsOverflow {
    display: flex;
    overflow: hidden;
    width: 100%;
}


.dateBuy {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(14px, 2vw, 23px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    display: flex;
    width: -webkit-fill-available;
    align-items: center;
    justify-content: center;
    z-index: 2;
    margin-top: 28px;
}


.titleShop {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    gap: 64px;
}

.titleShop span {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 49px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.areaCardsProducts {
    width: 100%;
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
}

.itemProduct {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    width: clamp(160px, 24vw, 305px);
    height: auto;
    overflow: hidden;
    cursor: pointer;
}

.backImageCard {
    position: absolute;
    top: 0;
    z-index: 1;
    height: 100%;
}

.backImageCard img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.imageItem {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: -webkit-fill-available;
    margin-top: 30px;
}

.imageItem img {
    max-width: clamp(116px, 20vw, 258px);
    max-height: clamp(116px, 20vw, 258px);
}


.areaNameProd {
    padding: 9px 24px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    width: -webkit-fill-available;
    justify-content: space-between;
    gap: 10px;
}

.nameProd {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(17px, 2.5vw, 32px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.prices {
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: center;
}

.priceMain {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(16px, 2vw, 28px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.priceConverted {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(12px, 2vw, 18px);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.smallText {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(22px, 4vw, 30px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-bottom: 16px;
}

.nameBuyProd {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(14px, 1.5vw, 26px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.nameUser {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(16px, 2vw, 32px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.buyInfo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.areaCardsProductsBuy {
    width: max-content;
    display: flex;
    gap: 25px;

    padding: 0 12px;
}

.popupBuy {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(15, 15, 15, 0.60);
    backdrop-filter: blur(5.849999904632568px);
    z-index: 999999;
}

.closePop {
    position: absolute;
    right: 24px;
    top: 24px;
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    background: rgba(5, 7, 7, 0.89);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.inPopup {
    max-width: 1192px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px;
    position: relative;
    top: 0;

}

.mainAreaPop {
    display: flex;
    align-items: flex-start;

    width: 100%;
    justify-content: space-between;
}

.leftPartPop {
    max-width: 708px;
    width: 100%;
}

.nameProduct {
    display: flex;
    gap: 20px;
    align-items: center;

    margin-bottom: 20px;
}

.nameProduct span {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(24px, 4vw, 40px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.descProductBuy pre {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(18px, 4vw, 24px);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 12px;
}

.descProductBuy pre::-webkit-scrollbar {
    width: 13px;
    border-radius: 5px;
    background: rgba(143, 143, 143, 0.301);
}

.descProductBuy pre::-webkit-scrollbar-thumb {
    border-radius: 5px;
    width: 3px;
    background: rgb(175, 175, 175);
}

.rightPartPop {
    max-width: 322px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightPartPop img {
    max-width: clamp(120px, 25vw, 300px);
    max-height: clamp(125px, 35vw, 300px);
    object-fit: cover;
}

.simpleTextProduct {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.bottomInputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 87px;
}

.areaInputs {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.areaInputs label {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.areaInputs label span {
    color: #FF3838;
}

.areaInputs input {
    display: flex;
    max-width: 327px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;

    padding: 12px 6px;
    color: #B9B9B9;
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    border-radius: 12px;
    border: 1px solid #2D3431;
    background: #131B1A;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
}

.resultArea {
    display: flex;
    flex-direction: column;

}

.textRes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 361px;
    width: 100%;
    margin-bottom: 10px;
    align-items: flex-end;
}

.textResTitle {
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 23px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.priceRes {
    display: flex;
    flex-direction: column;
}

.mainMoney {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.convertMoney {
    color: #FFF;
    text-align: right;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.btnToCart {
    display: flex;
    width: 361px;
    height: 56px;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 12px;
    background: linear-gradient(91deg, #FE6844 15.81%, #FFCA46 83.76%);
    color: #F3F3F3;
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.btnToCart:hover {
    transition: .5s;
    background: linear-gradient(91deg, #ff4a1d 15.81%, #ffbd17 83.76%);
}

.notification {
    display: none;
    position: fixed;
    top: 54px;
    right: 54px;
    padding: 24px;
    border-radius: 12px;
    background: linear-gradient(91deg, #FE6844 15.81%, #FFCA46 83.76%);
    color: #FFF;
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    z-index: 999999;
}

.simplePriceFont {
    font-size: clamp(10px, 1.2vw, 14px);
    font-weight: 400 !important;
}

@media screen and (max-width: 1350px) {

    .areaCardsProducts,
    .areaCardsProductsBuy {
        gap: 15px;
        display: grid;
        width: -webkit-fill-available;
        padding: 0 24px;
        grid-template-columns: repeat(auto-fit, minmax(clamp(160px, 20vw, 305px), 1fr));
    }

    .itemProduct {
        width: clamp(160px, 22vw, 305px);
    }

    .titleShop span {
        font-size: clamp(20px, 8vw, 49px);
    }

    .titleShop svg,
    .nameProduct svg {
        transform: scale(clamp(0.5, 0.68, 1));
    }

    .titleShop {
        gap: clamp(24px, 5vw, 64px);
    }

    .inShopBuy {
        width: -webkit-fill-available;
        padding: 0 12px;
    }

    .titleShop {
        margin-bottom: 24px;
    }

    .inPopup {
        padding: clamp(24px, 3vw, 48px);
        min-width: 550px
    }
}

@media screen and (max-width: 1300px) {

    .mainAreaPop {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        flex-direction: column-reverse;
    }

    .nameProduct {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 20px;
        justify-content: center;
    }

    .leftPartPop {
        margin-top: 24px;
    }

    .inPopup {
        border-radius: 64px;
        overflow: hidden;
        width: unset;
        min-width: unset;

    }

    .rightPartPop img {
        max-width: clamp(120px, 15vw, 400px);
        max-height: clamp(125px, 55vw, 400px);
        object-fit: cover;
    }

    .bottomInputs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        flex-direction: column;
        gap: 16px;
    }

    .bottomInputs {
        padding: 0 12px;
        width: -webkit-fill-available;
        padding-bottom: 80px;
    }

    .areaInputs input {
        width: unset;
        max-width: unset;
    }

    .textRes {
        max-width: unset;
    }

    .btnToCart {
        width: 100%;
    }
    .descProductBuy pre{
        height: 150px;
    }

}

@media screen and (max-width: 1050px) {
    .parallaxLayer {
        top: 104px;
    }
}

@media screen and (max-width: 800px) {
    .imageItem {
        margin-top: 24px;
    }

    .areaNameProd {
        padding: clamp(5px, 7px, 9px) clamp(14px, 18px, 24px);
    }

    .mainAreaPop {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        flex-direction: column-reverse;
    }

    .descProductBuy pre {

        font-size: clamp(20px, 4vw, 18px);
    }


    .textResTitle {
        font-size: 18px;
    }

    .mainMoney {
        font-size: 20px;
    }

    .convertMoney {
        font-size: 14px;
    }

    .closePop {
        top: 34px;
        right: 34px;
    }

    .closePop {
        height: 42px;
        width: 42px;
    }

    .inPopup {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        border-radius: 0;
        background-color: #2F5047 !important;
        background-image: url(./images/backgroundmobpopup.png) !important;
        align-items: center;
        overflow-y: auto;
        justify-content: start;
        width: -webkit-fill-available;
    }

    .mainAreaPop {
        margin-top: 55px;
    }


    .rightPartPop img {
        max-width: clamp(120px, 25vw, 400px);
        max-height: clamp(125px, 35vw, 400px);
        object-fit: cover;
    }
    .parallaxContainer{
        height: 65vw;
    }
}

@media screen and (max-width: 770px) {
    .itemProduct {
        width: clamp(160px, 28vw, 226px);
    }

    .backImageCard {
        width: 100%;
    }
}

@media screen and (max-width: 595px) {
    .itemProduct {
        width: clamp(160px, 45vw, 100%);
    }

    .backImageCard {
        width: 100%;
    }

    .imageItem img {
        max-width: 100%;
        max-height: clamp(116px, 35vw, 258px);
    }
}

@media screen and (max-width: 450px) {
    .itemProduct {
        width: clamp(160px, 95vw, 100%);
    }
    .parallaxContainer{
        height: 75vw;
    }
    .backImageCard {
        width: 100%;
    }

    .areaCardsProducts {
        gap: 15px;
        display: grid;
        width: -webkit-fill-available;
        padding: 0 24px;
        grid-template-columns: repeat(auto-fit, minmax(clamp(160px, 50vw, 305px), 1fr));
    }

    .nameProd {
        font-size: clamp(17px, 5vw, 32px);
    }

    .priceMain {
        font-size: clamp(16px, 5vw, 28px);
    }

    .priceConverted {
        font-size: clamp(10px, 4.2vw, 14px);
    }
}