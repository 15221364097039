.Bans {
    display: flex;
    flex-direction: column;
    height: auto;
}


.parallaxContainer {
    position: relative;
    height: 55vw;
    /* Сделаем страницу достаточно длинной для демонстрации прокрутки */
    overflow: hidden;
}

.parallaxLayer {
    position: absolute;
    width: 100%;
    height: 100vw;
    /* Каждый слой занимает весь экран по высоте */
    top: 0;
    left: 0;
}

.parallaxImage {
    width: 100%;
    height: auto;
    object-fit: fill;
    /* Для корректного отображения изображений */
}


.parallax {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;
    overflow: hidden;

}

.areaBans {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.inareaBan {
    max-width: 1296px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.titleBans {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: clamp(24px, 3vw, 64px);

}

.titleBans span {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(24px, 4vw, 49px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
    margin-left: clamp(24px, 10vw, 97px);
    margin-right: clamp(24px, 10vw, 97px);
}

.areaTablesinfo {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.btsSortTable {
    border-radius: 16px;
    background: #192120;
    display: flex;
    display: inline-flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 19px;
    width: max-content;
    margin-bottom: 48px;
}

.itemSort {
    display: flex;
    padding: 15px 40px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.itemSort:hover {
    border-radius: 16px;
    background: #283332;
}

.tableArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.headerTable {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.itemHT {
    color: #FFAE45;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    max-width: 168px;
    width: 100%;
    padding: 12px 0px;
}

.mainTable {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    background: #192120;
    padding: 24px 0px;
}

.tableItem {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    max-width: 168px;
    width: 100%;
    word-break: break-all;
    text-align: center;
    padding: 0 12px;
    height: 28px;
    overflow: hidden;
}

.tableItem:last-child {
    height: auto;
}

.pagination {
    width: max-content;
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 19px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    user-select: none;
}

.numbersPag {
    display: flex;
    gap: 8px;
}

.prePag,
.itemPag,
.nextPag {
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.prePag:hover,
.itemPag:hover,
.nextPag:hover {
    border-radius: 12px;
    background: #192120;
}







.connectToPlaystrix {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: auto;

}

.inConnectToPLaystrix {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 1278px;
}

.topImageConnect {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 31;
}

.topImageConnect img {
    width: 100%;
    height: 3%;
    object-fit: cover;
}

.mainAreaConnect {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 159px;
    width: 100%;
    height: 100%;
}

.mainFooterImageBack {
    width: 100%;
    height: 100%;
}

.mainFooterImageBack img {
    width: auto;
    height: 80%;
    object-fit: fill;
}

.textConnect {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    -webkit-text-stroke-width: 2;
    -webkit-text-stroke-color: #194450;
    text-shadow: -1px 1px 0 #000,
        1px 1px 0 #000,
        1px -1px 0 #000,
        -1px -1px 0 #000;
    font-family: Nunito;
    font-size: 66px;
    font-style: normal;
    font-weight: 900;
    line-height: 99.02%;
    max-width: 1044px;
    position: absolute;
    z-index: 31;
    top: 406px;
}

.socialIcon {

    top: 0;
    z-index: 35;
    top: 600px;
}

.areaSocIcons{
    position: absolute;
    top: 600;
    z-index: 35;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
}



.bottomImageConnect {
    z-index: 31;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.inBottomImage {
    position: relative;
    display: flex;
    height: 650px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.rocksImage {
    bottom: 0;
    width: 100%;
    position: absolute;
}

.grassImage {
    width: 100%;
    bottom: 50px;
    position: absolute;
}

.grassImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.rocksImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.activePag {
    background: #192120;
}

.currentPunish {
    background: #283332;
    border-radius: 16px;
}

@media screen and (max-width: 1050px) {
    .areaTablesinfo {
        width: -webkit-fill-available;
        overflow: auto;
        padding-bottom: 10px;
        padding: 0 12px;
    }

    .areaTablesinfo::-webkit-scrollbar {
        background: rgb(95, 95, 95);
        height: 12px;

    }

    .areaTablesinfo::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: #192120;
    }

    .tableArea {
        width: 1000px;

    }

    .textConnect {
        font-size: clamp(24px, 7vw, 66px);
    }

    .topImageConnect {
        top: 45px;
    }

    .inBottomImage {
        height: clamp(350px, 25vw, 650px);
    }

    .mainAreaConnect {
        top: clamp(34px, 25vw, 159px);
    }

    .inConnectToPLaystrix {
        height: clamp(400px, 95vw, 1278px);
    }

    .rocksImage {
        bottom: -5px;
    }

    .textConnect {
        top: clamp(90px, 18vw, 406px);
    }

    .socialIcon {
        top: clamp(200px, 45vw, 600px);
    }

    .socialIcon svg {
        width: clamp(45px, 3vw, 65px);
    }

    .grassImage {
        width: 100%;
        bottom: 0px;
        position: absolute;
    }

    .Bans {
        margin-top: 104px;
    }

    .currentPunish,
    .itemSort {
        font-size: clamp(16px, 3vw, 19px);
        padding: clamp(8px, 3vw, 15px) clamp(24px, 3vw, 40px);
    }

    .itemHT {
        font-size: clamp(16px, 3vw, 19px);
    }

    .tableItem {
        font-size: clamp(15px, 1vw, 19px);
        max-width: clamp(148px, 2vw, 168px);
    }

    .mainTable {
        padding: clamp(16px, 3vw, 24px) 0px;
    }
}