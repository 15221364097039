.footer {
    padding: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inFooter {
    padding: 33px 64px;
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
}

.topFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.areaTop {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.menu {
    display: flex;
    flex-wrap: wrap;
    max-height: 120px;
    gap: 40px;
    justify-content: space-between;
}

.itemMenu {
    color: #EA8345;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-align: center;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.menuItems {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.blockSocial {
    display: flex;
    gap: 8px;
    width: 100%;
    align-items: end;
    justify-content: end;
}

.itemSoc {
    cursor: pointer;
}

.ipSelector {
    display: inline-flex;
    padding: 7px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    border-radius: 24px;
    border: 3px solid #FF6E46;
    background: rgba(35, 35, 35, 0.30);
    backdrop-filter: blur(1px);
    color: #FFF;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    gap: 4px;
    cursor: pointer;
    margin-top: 12px;
}

.areaBottom {
    display: flex;
    margin-top: 24px;
    flex-direction: row;
    color: #EA8345;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    justify-content: space-between;
    gap: 40px;
}

.boldText {
    color: #EA8345;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.areaBotFooter {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1050px) {
    .topFooter {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    .areaTop {
        width: 100%;
    }

    .areaBottom {
        display: flex;
        margin-top: 24px;
        flex-direction: column;
        color: #EA8345;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
        font-family: Nunito;
        font-size: 13px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        justify-content: space-between;
        gap: 40px;
    }

    .areaBotFooter {
        display: flex;
        gap: 12px;
        flex-direction: row;
    }
    .areaBotFooterAdd{
        flex-direction: column;
    }
    .inFooter{
        padding: 12px;
    }
    .logo svg{
        max-width: 250px;
        width: 65%;
    }
}