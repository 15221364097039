.policyArea {
    width: 100%;
    display: flex;

}

.inPolicyArea {
    max-width: 1296px;
    width: -webkit-fill-available;
    padding: 0 clamp(12px,5vw,24px);
    margin-top: 126px;
    margin-bottom: 64px;
}

.titlePolicy {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(24px,5vw,49px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    gap: 24px;
}

.textPolicy {
    max-width: 1296px;
    width: -webkit-fill-available;
    padding: 0 clamp(12px,5vw,24px);
    text-indent: 14px;
    color: #FFF;
    text-shadow: 0px 2.982px 2.982px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}