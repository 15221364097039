.parallaxContainer {
    position: relative;
    height: 50vw;
    /* Сделаем страницу достаточно длинной для демонстрации прокрутки */
    overflow: hidden;
}

.parallaxLayer {
    position: absolute;
    width: 100%;
    height: 100%;
    /* Каждый слой занимает весь экран по высоте */
    top: 0;
    left: 0;
}

.parallaxImage {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.parallax {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    align-items: center;
    overflow: hidden;

}

.titleGalaryMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blockTextGallary {
    max-width: 798px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tileGallary {
    display: flex;
    gap: 64px;

    align-items: center;
    justify-content: center;
}

.tileGallary span {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 49px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.descGallary {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFF;
    text-align: center;
    font-family: Nunito;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.splitter {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: clamp(64px, 5vw, 128px);
}

.splitter svg {
    width: 100%;
}

.selectTypeGallary {
    padding: 52px;
    max-width: 1248px;
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
}

.itemSelect {
    color: #805F4B;
    text-align: right;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 36px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    cursor: pointer;
}

.itemSelect:hover {
    color: #fff;
}

.itemSelect:hover::after {
    content: ' •';
}

.itemSelect:hover::before {
    content: '• ';
}

.selecteditemSelect {
    color: #fff;
}

.selecteditemSelect::after {
    content: ' •';
}

.selecteditemSelect::before {
    content: '• ';
}

.gallaryImages {
    display: flex;
    margin-top: 77px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.inGallaryImage {
    max-width: 1296px;
    gap: 18px;
    width: -webkit-fill-available;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 12px;
}

.itemsGallaryImage {
    margin-bottom: 48px;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.imageGall {
    width: 100%;
    height: 236px;
    border-radius: 32px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageGall img {
    width: auto;
    height: 150%;
    object-fit: fill;
}

.areaInfoGall {
    margin-top: 16px;
}

.titleGallImg {
    color: #F4F4F4;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.descImage {
    color: #466A64;
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@keyframes pulse {
    0% {
        background-color: #ddd;
    }

    50% {
        background-color: #ccc;
    }

    100% {
        background-color: #ddd;
    }
}

@media screen and (max-width:1296px) {}

@media screen and (max-width:1050px) {
    .parallaxContainer {
        margin-top: 104px;
    }

    .tileGallary span {
        font-size: clamp(32px, 5vw, 49px);
    }

    .descGallary {
        font-size: clamp(18px, 5vw, 26px);
    }

    .tileGallary svg {
        width: 24px;
    }

    .blockTextGallary {
        padding: 0 12px;
        width: unset;
    }

    .selectTypeGallary {
        padding: clamp(24px, 5vw, 52px);

    }

    .itemSelect {
        font-size: clamp(24px, 5vw, 36px);
        padding: 0 12px;
    }

    .splitter {
        margin-top: clamp(24px, 15vw, 128px);
    }

    .selectTypeGallary {
        padding: clamp(24px, 3vw, 52px);
        max-width: 1248px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    .gallaryImages {
        display: flex;
        margin-top: clamp(24px, 4vw, 77px);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .itemsGallaryImage {
        margin-bottom: clamp(12px, 3vw, 48px);
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .inGallaryImage {
        width: -webkit-fill-available;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 12px;
    }
}

@media screen and (max-width:800px) {
    .inGallaryImage {
        width: -webkit-fill-available;
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 12px;
    }
}


@media screen and (max-width:600px) {
    .inGallaryImage {
        width: -webkit-fill-available;
        display: grid;
        grid-template-columns: 1fr;
        padding: 0 12px;
    }
}