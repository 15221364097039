@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400..800&family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

.header {
    width: 100%;
    position: fixed;
    background: rgba(128, 128, 128, 0.205);
    z-index: 999999;
}

.inHeader {
    display: flex;
    padding: 24px 48px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.shadowHeader {
    background: rgb(0 0 0 / 90%);
    transition: 1s;
}

.header__block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ipLocalization {
    display: flex;
    align-items: center;
    justify-content: center;

}

.menu {
    display: flex;
    gap: clamp(12px, 3vw, 56px);
    user-select: none;
}

.areaCartIcon {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

.cartIcon {
    width: 40px;
    height: auto;
}

.indicatorAmount {
    position: absolute;
    top: -4px;
    right: 4px;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #FF9645;
    padding: 2px;
    display: flex;
    color: #FFF;
    font-variant-numeric: lining-nums tabular-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

.itemMenu {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: clamp(18px, 3vw, 24px);
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    cursor: pointer;
}

.itemMenu:hover {
    color: #FFAE45;
}

.ipLocalization {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.ipSelector,
.onlineModule {
    gap: 8px;
}

.ipcopy {
    color: #FFF;
    font-family: Nunito;
    font-size: clamp(18px, 3vw, 24px);
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    cursor: pointer;

}


.ipSelector,
.onlineModule {
    position: relative;
    display: inline-block;
    padding: clamp(4px, 3vw, 10px) clamp(8px, 3vw, 20px);
    font-family: Arial, sans-serif;
    font-size: clamp(16px, 3vw, 24px);
    color: white;
    z-index: 1;
    display: flex;
    align-items: center;
}

.ipSelector::before,
.onlineModule::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -1;
    border-radius: 30px;
    /* радиус скругления */
    background: linear-gradient(45deg, rgb(251, 255, 0), rgb(255, 153, 0), rgb(255, 81, 0));
    background-size: 300%;
    animation: gradient-animation 10s ease infinite;
}

.ipSelector::after,
.onlineModule::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    z-index: -1;
    border-radius: 26px;
    background: #1c1c1c;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.langSelector {
    display: flex;
    position: relative;
    margin-left: 16px;
}

.currentLangBlock {
    cursor: pointer;
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    font-family: Nunito;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 136.291%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.currentLang {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.selectorLang {
    display: none;
    position: absolute;
    top: 40px;
    right: 0px;
    z-index: 9999;

    flex-direction: column;
    background: #0B1110;
    padding: 8px;
    border: 1px solid #1D3028;
    border-radius: 8px;

}

.itemLang {
    width: 136px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 136.291%;
    justify-content: center;
}

.itemLang:hover {
    color: #FFAE45;
}

.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    transition: 0.5s;
}

.rotateArrow {
    transform: rotate(180deg);
    display: flex;
    margin-left: 4px;
    transition: 0.5s;
}

.open {
    display: flex;
}

.mobileHeader {
    display: none;
}

@media screen and (max-width: 1050px) {
    .mobileHeader {
        display: flex;
        position: fixed;
        top: 0;
        z-index: 999999;
        width: 100%;
        background: #131b19;

    }
    .inMobileHeader{
        padding: 24px 12px;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .header {
        display: none;
    }

    .burgerMenu {
        cursor: pointer;
    }

    .mobileMenu {
        position: absolute;
        top: 106px;
        left: 0;

        width: 100%;
        z-index: 99999;
    }

    .menu {
        padding: 24px 12px;
        display: flex;
        flex-direction: column;
        background-color: #2F5047;
        background-image: url(./assets/img/backgroundmobpopup.webp);
        height: 100vh;
        justify-content: start;
        user-select: none;
        background-repeat: no-repeat;
        background-size: cover;
    }
}